.Footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    font-size: 35px;
}

.footer-text {
    font-size: 20px;
}