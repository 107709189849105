.socials {
    display: flex;
    list-style: none;
    font-size: 20px;
}

.navbar {
    background-color: #274c77;
    width: 100vw;
}

.navbar-brand {
    font-size: 30px;
}

#resume {
    background-color: aquamarine;
    border: 1px solid aquamarine;
    border-radius: 30px;
    color:black;
    }

#resume:hover {
    color: aquamarine;
    background-color: black;
}