

/* General styles */
.project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    font-family: Arial, sans-serif;
  }
  
  .project-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 0 0 auto;
    padding: 10px;
  }

.details {
font-size: 1.5rem;
  }
  
  .project-img {
    height: 200px;
  }
  
  .project-name {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
  }
  
  .details {
    font-size: 1.3rem;
    font-family: 'Your Unique Font', sans-serif;
    color: #555;
    margin-top: 10px;
  }
  
  /* Media queries for responsiveness */
  @media only screen and (max-width: 600px) {
    .project-name {
      font-size: 2.5rem;
    }
  
    .project-description {
      font-size: 1rem;
    }
  
    .project-details {
      font-size: 1rem;
    }
  
    .project-container {
      display: flex;
      flex-direction: column;
    }
  
    .project-img {
      width: 100px;
      height: auto;
    }
  
    .project-details {
      background-color: transparent;
      padding: 0;
    }
  }
  
  /* Animations */
  .project-container {
    transition: all 0.3s ease;
  }
  
  .project-details {
    transition: all 0.3s ease;
  }
  
  /* Example animation: Scale on hover */
  .project-container:hover {
    transform: scale(1.05);
  }
  
  .project-details:hover {
    transform: scale(1.05);
  }
  