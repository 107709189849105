.Contact {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-inline: 300px;
}

form > * {
  border: none;
  font-size: 30px;
}

@media only screen and (max-width: 1333px) {
  form {
    margin-inline: 0;
  }
}
