.about-content {
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
}

.bit{
    width: 50%;
}

h3 {
    font-weight: bold;
}

@media only screen and (max-width: 900px) {
    .about-content {
        display: flex;
        flex-direction: column;
    }
    .bit {
        width:auto;
    }
}

@media only screen and (max-width: 600px) {
    .title {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 1.5rem;
    }
    .skills {
        font-size: 1rem;
    }
    p {
        font-size: 1rem;
    }
    .about-content {
        display: flex;
        flex-direction: column;
    }
    .bit {
        width:auto;
    }
    .About {
        height: fit-content;
    }
}

.skills-contain {
    margin-top: 40px;
}

.skills {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.headshot {
    height: 400px;
    max-height: 500px;
    max-width: 500px;
    border-radius: 20%;
}

ul.skills {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Style each skill item */
li.skill {
    background-color: #3498db;
    color: rgb(228, 241, 254);
    padding: 10px;
    border-radius: 5px;
    font-size: 1.5rem;
    margin: 0;
    transition: transform 0.3s ease-in-out;
    cursor: none;
}

/* Add hover animation */
li.skill:hover {
    transform: scale(1.1);
}

