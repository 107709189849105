.App {
  text-align: center;
  width: 100vw;
}

.main-pages {
  margin-left: 15%;
  margin-right: 15%;
}

.main-pages > .Home,
.Projects,
.About,
.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: left;
}

.title {
  text-align: center;
  font-size: 6rem;
  color: rgb(14, 66, 138);
}

@media only screen and (max-width: 600px) {
  body {
    width: 100vw;
    margin: 0;
  }
  .main-pages > .Home,
  .Projects,
  .About,
  .Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  h1 {
    font-size: 50px;
  }
}

/* ANIMATIONS */

.hidden {
  opacity: 0;
  transition: all 1.5s;
  filter: blur(5px);
  transform: translateX(-100%);
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}


@media (prefers-reduced-motion) {
  .hidden {
    transition: all 1s;
  }
}

/* Add more animations here */