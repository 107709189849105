/* .intro-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin-left: 15%;
    margin-right: 15%;
    text-align: left;
} */

.intro {
    font-size: 2rem;
}

.name {
    letter-spacing: 5px;
    font-size: 7.6rem;    
    font-weight: bold;
    color: rgb(14, 66, 138);
}

.one-liner {
    font-size: 1.8rem;
    color: rgb(68, 141, 161);
}

.description {
    font-size: 1.1rem;
}

@media only screen and (max-width: 600px) {
    .Home {
        display: flex;
        margin: none;
        justify-content: center;
        align-content: top;
    }
    .intro {
        font-size: 1.3rem;
    }
    .name {
        font-size: 3.3rem;
    }
    .one-liner {
        font-size: 1.3rem;
    }
    .description {
        font-size: 1.3rem;
    }
}